.single-card{
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.admin-cards{
   display: flex;
   padding: 20px;
   justify-content: center;
}